<template>
<div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
    <h3>User login</h3>
    <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
    </button>
    </div>
    <div class="modal-content">
    <!-- Contenido de tu modal aquí -->
    <form class="login__form form__desktop" method="post" :action="`//wager.${BackEndUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
        <input class="login__form--inputs" type="text" placeholder="Username" name="account" id="account">
        <input class="login__form--inputs" type="password" name="password" placeholder="Password" id="password">
        <div class="header-buttons">
        <button class="login-button" type="submit">Login</button>
        <!-- <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a> -->
        </div>
        </form>
    <form class="login__form form__mobile" method="post" :action="`//mobile.${BackEndUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
        <input class="login__form--inputs" type="text" placeholder="Username" name="account" id="account">
        <input class="login__form--inputs" type="password" placeholder="Password" name="password" id="password">
        <div class="header-buttons">
        <button class="login-button" type="submit">Login</button>
        <!-- <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a> -->
        </div>
    </form>
    </div>
</div>
</template>

<script>
export default {
    name: 'ModalComponentClassic',
    setup:() => {
    const BackEndUrl = "wagerstore.com";
    const DGS_SITEID = 961;
    return {
        BackEndUrl,
        DGS_SITEID
    }
    },
    data() {
    return {
        showModal: false
    }
    },
    methods: {
    openModal() {
        this.showModal = true;
    },
    closeModal() {
        this.showModal = false;
    }
    }
}
</script>