<template>
  <footer class="footer">
    <div class="footer-content">
      <agent-button/>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn2" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn3" :key="index">
          <a :href="route.route">{{ route.tel }}</a>
        </li>
      </ul>
    </div>
    <div class="footer-content__rights">
      <p>Wager Store © All Rights Reserved</p>
    </div>
    <mobile-menu/>
  </footer>
</template>


<script>
  import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue'

  export default {
    name: "FooterComponent",
    components: {
      AgentButton,
      MobileMenu
    },
    setup() {
      return {
        routesColumn1: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          }
        ],
        routesColumn2: [
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'horse rules',
            route      :  'horse-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ],
        routesColumn3: [
          {
            tel       :  '1-866-895-4647',
            route      :  'tel:+1-866-895-4647'
          }
        ]
      }
    },
  };
</script>
