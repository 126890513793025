<template>
  <brand-header :image="urlImage" :altImage="altImagen"/>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <!-- <menu-component/> -->
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <span class="material-symbols-rounded">menu</span>
        </button> -->
        <a href="/" class="header-content__container-logo">
          <img class="header-content__img" src="../assets/images/logo.png" alt="wagerStore" />
        </a>
      </div>
      <div class="header-content__cta">
        <!-- <span class="header-content__language material-symbols-rounded">language</span> -->
        <div class="header-buttons">
          <a href="https://agents.wagerstore.com/AgentSiteV2/" target="_blank" class="btn-login secondary-button secondary-button__agent">Agent</a>
        </div>
        <div class="header-buttons">
          <!-- <a href="#" class="btn-login--classic secondary-button secondary-button__classic">classic</a> -->
          <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">login</a>
        </div>
      </div>
    </div>
    <div class="header-content__info">
      <ul class="header-content__info--list">
        <li class="header-content__info--list-item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.item }}</router-link>
        </li>
      </ul>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
  import ModalComponent from '@/components/modal-login-dgs.vue'
  import BrandHeader from '@/components/brand-header.vue';
  // import MenuComponent from '@/components/menu.vue'

  export default {
    name: "HeaderComponent",
    components: {
      ModalComponent,
      BrandHeader
      // MenuComponent
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      return {
        urlImage: require('../assets/images/logo.png'),
        altImage: 'wagerStore',

        routes: [
          {
            item       :  'sports',
            route      :  'sportsbook'
          },
          {
            item       :  'live betting',
            route      :  'live-betting'
          },
          {
            item       :  'casino',
            route      :  'casino'
          },
          {
            item       :  'racebook',
            route      :  'racebook'
          },
          {
            item       :  'props builder',
            route      :  'props-builder'
          }
        ]
      }
    },
  };
</script>
