<template>
  <section class="agent-buttons">
    <img src="../assets/images/logo.png" alt="wagerStore">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agent classic</a>
    <a class="primary-button primary-button__new" target="_blank" @click="openModal">Betslip Login</a>
  </section>
  <ModalLoginBetslip ref="modal" />
</template>

<script>
  import ModalLoginBetslip from '@/components/modal'
  export default {
    name: "AgentsButtons",
    components: {
      ModalLoginBetslip
    },
    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
    setup() {
      const agentClassic = "https://agents.wagerstore.com/AgentSiteV2/";
      const agentNew = "https://adm.wagerstore.com/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
